// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.escapeExpression;

  return "    <h2 class=\"info\">\n        "
    + alias3((helpers.__||(depth0 && depth0.__)||container.hooks.helperMissing).call(alias2,"In progress",{"name":"__","hash":{},"data":data}))
    + " : "
    + alias3(container.lambda(((stack1 = (depth0 != null ? depth0.inProgress : depth0)) != null ? stack1.length : stack1), depth0))
    + "\n    </h2>\n\n    <ul class=\"entry-point-box plain\">\n"
    + ((stack1 = helpers.each.call(alias2,(depth0 != null ? depth0.inProgress : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </ul>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.lambda, alias4=container.escapeExpression, alias5=container.hooks.helperMissing;

  return "        <li>\n            <a class=\"block entry-point entry-point-started-deliveries link-to-lti "
    + ((stack1 = helpers.unless.call(alias2,((stack1 = (depth0 != null ? depth0.lineItem : depth0)) != null ? stack1.isActive : stack1),{"name":"unless","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"\n               data-launch_url=\""
    + alias4(alias3(((stack1 = (depth0 != null ? depth0.lineItem : depth0)) != null ? stack1.uri : stack1), depth0))
    + "\"\n               data-assignment_id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias5),(typeof helper === "function" ? helper.call(alias2,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n                <h3>"
    + alias4(alias3(((stack1 = (depth0 != null ? depth0.lineItem : depth0)) != null ? stack1.label : stack1), depth0))
    + "</h3>\n                <div class=\"clearfix\">\n"
    + ((stack1 = helpers["if"].call(alias2,(depths[1] != null ? depths[1].displayAttemptAccounts : depths[1]),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    <span class=\"text-link\"><span class=\"icon-continue\"></span> "
    + alias4((helpers.__||(depth0 && depth0.__)||alias5).call(alias2,"Resume",{"name":"__","hash":{},"data":data}))
    + " </span>\n                </div>\n            </a>\n        </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4=container.escapeExpression;

  return "                        <span class=\"attempts\">"
    + alias4((helpers.__||(depth0 && depth0.__)||alias3).call(alias2,"Attempts",{"name":"__","hash":{},"data":data}))
    + ": "
    + alias4(((helper = (helper = helpers.attemptsCount || (depth0 != null ? depth0.attemptsCount : depth0)) != null ? helper : alias3),(typeof helper === "function" ? helper.call(alias2,{"name":"attemptsCount","hash":{},"data":data}) : helper)))
    + " / "
    + alias4(container.lambda(((stack1 = (depth0 != null ? depth0.lineItem : depth0)) != null ? stack1.maxAttempts : stack1), depth0))
    + "</span>\n";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.escapeExpression;

  return "    <h2>\n        "
    + alias3((helpers.__||(depth0 && depth0.__)||container.hooks.helperMissing).call(alias2,"Available",{"name":"__","hash":{},"data":data}))
    + ": "
    + alias3(container.lambda(((stack1 = (depth0 != null ? depth0.available : depth0)) != null ? stack1.length : stack1), depth0))
    + "\n    </h2>\n    <ul class=\"entry-point-box plain\">\n"
    + ((stack1 = helpers.each.call(alias2,(depth0 != null ? depth0.available : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </ul>\n";
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.propertyIsEnumerable, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), alias5=container.hooks.helperMissing;

  return "            <li>\n                <a class=\"block entry-point entry-point-all-deliveries link-to-lti\"\n                data-launch_url=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.lineItem : depth0)) != null ? stack1.uri : stack1), depth0))
    + "\"\n                data-assignment_id=\""
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias5),(typeof helper === "function" ? helper.call(alias4,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n                    <h3>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.lineItem : depth0)) != null ? stack1.label : stack1), depth0))
    + "</h3>\n                    <div class=\"clearfix\">\n"
    + ((stack1 = helpers["if"].call(alias4,(depths[1] != null ? depths[1].displayAttemptAccounts : depths[1]),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    <span class=\"text-link\"><span class=\"icon-play\"></span> "
    + alias3((helpers.__||(depth0 && depth0.__)||alias5).call(alias4,"Start",{"name":"__","hash":{},"data":data}))
    + " </span>\n                    </div>\n                </a>\n            </li>\n";
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.escapeExpression;

  return "        <h2 class=\"info\">\n            "
    + alias3((helpers.__||(depth0 && depth0.__)||container.hooks.helperMissing).call(alias2,"Completed",{"name":"__","hash":{},"data":data}))
    + " : "
    + alias3(container.lambda(((stack1 = (depth0 != null ? depth0.completed : depth0)) != null ? stack1.length : stack1), depth0))
    + "\n        </h2>\n\n        <ul class=\"entry-point-box plain\">\n"
    + ((stack1 = helpers.each.call(alias2,(depth0 != null ? depth0.completed : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </ul>\n";
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.propertyIsEnumerable, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), alias5=container.hooks.helperMissing;

  return "                <li>\n                    <a class=\"block entry-point entry-point-all-deliveries disabled\"\n                       data-launch_url=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.lineItem : depth0)) != null ? stack1.uri : stack1), depth0))
    + "\"\n                       data-assignment_id=\""
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias5),(typeof helper === "function" ? helper.call(alias4,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n                        <h3>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.lineItem : depth0)) != null ? stack1.label : stack1), depth0))
    + "</h3>\n                        <div class=\"clearfix\">\n"
    + ((stack1 = helpers["if"].call(alias4,(depths[1] != null ? depths[1].displayAttemptAccounts : depths[1]),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                            <span class=\"text-link\"><span class=\"icon-play\"></span> "
    + alias3((helpers.__||(depth0 && depth0.__)||alias5).call(alias4,"Start",{"name":"__","hash":{},"data":data}))
    + " </span>\n                        </div>\n                    </a>\n                </li>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4=container.escapeExpression;

  return "                                <span class=\"attempts\">"
    + alias4((helpers.__||(depth0 && depth0.__)||alias3).call(alias2,"Attempts",{"name":"__","hash":{},"data":data}))
    + ": "
    + alias4(((helper = (helper = helpers.attemptsCount || (depth0 != null ? depth0.attemptsCount : depth0)) != null ? helper : alias3),(typeof helper === "function" ? helper.call(alias2,{"name":"attemptsCount","hash":{},"data":data}) : helper)))
    + " / "
    + alias4(container.lambda(((stack1 = (depth0 != null ? depth0.lineItem : depth0)) != null ? stack1.maxAttempts : stack1), depth0))
    + "</span>\n";
},"14":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.escapeExpression;

  return "        <h2 class=\"info\">\n            "
    + alias3((helpers.__||(depth0 && depth0.__)||container.hooks.helperMissing).call(alias2,"Unavailable",{"name":"__","hash":{},"data":data}))
    + " : "
    + alias3(container.lambda(((stack1 = (depth0 != null ? depth0.unavailable : depth0)) != null ? stack1.length : stack1), depth0))
    + "\n        </h2>\n\n        <ul class=\"entry-point-box plain\">\n"
    + ((stack1 = helpers.each.call(alias2,(depth0 != null ? depth0.unavailable : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </ul>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"test-listing assignments-list\">\n    <h1>"
    + container.escapeExpression((helpers.__||(depth0 && depth0.__)||container.hooks.helperMissing).call(alias2,"My Tests",{"name":"__","hash":{},"data":data}))
    + "</h1>\n    <div class=\"permanent-feedback\"></div>\n"
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.inProgress : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.available : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.completed : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.unavailable : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true,"useDepths":true});
