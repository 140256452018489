// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data,blockParams) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "    <input type=\"hidden\" name=\""
    + alias2(alias1(blockParams[0][1], depth0))
    + "\" value=\""
    + alias2(alias1(blockParams[0][0], depth0))
    + "\">\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, helper, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return "<form id=\"ltiForm\" action=\""
    + container.escapeExpression(((helper = (helper = helpers.ltiLink || (depth0 != null ? depth0.ltiLink : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias2,{"name":"ltiLink","hash":{},"data":data,"blockParams":blockParams}) : helper)))
    + "\" method=\"POST\" style=\"display: none\">\n"
    + ((stack1 = helpers.each.call(alias2,(depth0 != null ? depth0.ltiParams : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 2, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "</form>";
},"useData":true,"useBlockParams":true});
