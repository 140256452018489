// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4=container.escapeExpression;

  return "<div class=\"maintenance\">\n    <div> \n        <h1>"
    + alias4((helpers.__||(depth0 && depth0.__)||alias3).call(alias2,"Maintenance",{"name":"__","hash":{},"data":data}))
    + "</h1>\n        <p>"
    + alias4((helpers.__||(depth0 && depth0.__)||alias3).call(alias2,"We're down for maintenance. Be right back!",{"name":"__","hash":{},"data":data}))
    + "</p>\n    </div>\n</div>";
},"useData":true});
