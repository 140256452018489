// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"logo-container\">\n            <div>\n                <img src=\"img/big-logo.png\" alt=\"TAO Logo\">\n            </div>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4=container.escapeExpression;

  return "                <div>\n                    <label class='form_desc' for='password'>"
    + alias4((helpers.__||(depth0 && depth0.__)||alias3).call(alias2,"Password",{"name":"__","hash":{},"data":data}))
    + "<abbr title='"
    + alias4((helpers.__||(depth0 && depth0.__)||alias3).call(alias2,"This field is required",{"name":"__","hash":{},"data":data}))
    + "'>*</abbr></label><input type='password' name='password' id='password' value=\"\" />\n                </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4=container.escapeExpression;

  return "                <div class=\"viewable-hiddenbox\">\n                    <label class='form_desc' for='password'>"
    + alias4((helpers.__||(depth0 && depth0.__)||alias3).call(alias2,"Password",{"name":"__","hash":{},"data":data}))
    + "<abbr title='"
    + alias4((helpers.__||(depth0 && depth0.__)||alias3).call(alias2,"This field is required",{"name":"__","hash":{},"data":data}))
    + "'>*</abbr></label>\n                    <input type='password' name='password' id='password' value=\"\" class=\"viewable-hiddenbox-input\" />\n                    <span class=\"viewable-hiddenbox-toggle\" data-identifier=\"password-toggle\"></span>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4=container.escapeExpression;

  return "<div class=\"login-container\">\n"
    + ((stack1 = helpers["if"].call(alias2,(helpers.isClient||(depth0 && depth0.isClient)||alias3).call(alias2,"depp",(depth0 != null ? depth0.integrationClient : depth0),{"name":"isClient","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <div id=\"login-box\" class=\"login-box entry-point entry-point-container\">\n        <h1>"
    + alias4((helpers.__||(depth0 && depth0.__)||alias3).call(alias2,"Connect to the TAO platform",{"name":"__","hash":{},"data":data}))
    + "</h1>\n        <div class='xhtml_form'>\n            <form id='loginForm' name='loginForm' enctype=\"application/x-www-form-urlencoded\" onsubmit=\"return false\">\n                <div>\n                    <label class='form_desc' for='username'>"
    + alias4((helpers.__||(depth0 && depth0.__)||alias3).call(alias2,"Login",{"name":"__","hash":{},"data":data}))
    + "<abbr title='"
    + alias4((helpers.__||(depth0 && depth0.__)||alias3).call(alias2,"This field is required",{"name":"__","hash":{},"data":data}))
    + "'>*</abbr></label><input type='text' name='username' id='username' autofocus='autofocus' value=\"\" />\n                </div>\n"
    + ((stack1 = helpers.unless.call(alias2,(depth0 != null ? depth0.showPassword : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "                <div class='form-toolbar'>\n                    <input type='submit' id='connect' name='connect' value=\""
    + alias4((helpers.__||(depth0 && depth0.__)||alias3).call(alias2,"Log in",{"name":"__","hash":{},"data":data}))
    + "\" />\n                </div>\n            </form>\n        </div>\n    </div>\n</div>";
},"useData":true});
