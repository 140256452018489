// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "            <img src=\"img/logo.png\" alt=\"Logo\" id=\"tao-main-logo\">\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers.unless.call(alias2,(helpers.isClient||(depth0 && depth0.isClient)||container.hooks.helperMissing).call(alias2,"depp",(depth0 != null ? depth0.integrationClient : depth0),{"name":"isClient","hash":{},"data":data}),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "                <img src=\"img/logo.png\" alt=\"Logo\" id=\"tao-main-logo\">\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4=container.escapeExpression;

  return "    <nav class=\"rgt\">\n        <div class=\"settings-menu\">\n\n            <ul class=\"clearfix plain\">\n                <li class=\"infoControl sep-before\">\n                    <span class=\"a\">\n                        <span class=\"icon-test-taker\"></span>\n                        <span class=\"username\">"
    + alias4(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias3),(typeof helper === "function" ? helper.call(alias2,{"name":"username","hash":{},"data":data}) : helper)))
    + "</span>\n                    </span>\n                </li>\n                <li class=\"infoControl sep-before\">\n                    <a class=\"logout-button\" data-control=\"logout\" role=\"button\">\n                        <span class=\"icon-logout\"></span>\n                        <span class=\"text\">"
    + alias4((helpers.__||(depth0 && depth0.__)||alias3).call(alias2,"Logout",{"name":"__","hash":{},"data":data}))
    + "</span>\n                    </a>\n                </li>\n\n            </ul>\n        </div>\n    </nav>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return "<header class=\"dark-bar clearfix header-block\">\n    <span class=\"lft\">\n"
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.username : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "    </span>\n"
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.username : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</header>";
},"useData":true});
